// =========================================================================================@@
// Last Updated Date: Mar 11, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Spinner, View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const Button = ({
   active,
   activeBackgroundColor,
   activeTextColor,
   borderRadius,
   children,
   color,
   disabled,
   height: customHeight,
   icon,
   linkExact,
   linkIsExternal,
   linkTo,
   mode,
   name,
   onClick,
   onFocus,
   onMouseOver,
   paddingHorizontal,
   size,
   textColor,
   width
}) => {
   const height = customHeight || `var(--baseComponentHeight-${size})`
   const isLoading = mode === 'loading'
   // Button may be set as active directly
   // (ie. in the case of notes where we need to highlight 2 nights)
   // or automatically via NavLink component
   const activeButtonStyle = css`
      background-color: ${activeBackgroundColor};
      color: ${activeTextColor} !important;
      font-weight: 600 !important;
   `

   const disabledButtonStyle = css`
      opacity: 0.6;
      cursor: default;
   `

   let ButtonComponent = 'div'
   let buttonProps = {}

   if (linkTo && !disabled) {
      ButtonComponent = linkIsExternal ? 'a' : NavLink
      buttonProps = linkIsExternal
         ? { href: linkTo, target: '_blank', rel: 'noopener noreferrer' }
         : { to: linkTo, exact: linkExact, activeClassName: activeButtonStyle }
   }

   if (!linkIsExternal && typeof active === 'boolean') {
      buttonProps.isActive = () => active
   }

   return (
      <View display="inline-block" width={width} height={height}>
         <ButtonComponent
            onClick={disabled ? undefined : onClick}
            onFocus={onFocus}
            onMouseOver={onMouseOver}
            className={css`
               position: relative;
               display: inline-flex;
               justify-content: center;
               align-items: center;
               background-color: ${color};
               border-radius: ${borderRadius};
               width: 100%;
               height: 100%;
               color: ${textColor};
               line-height: ${height};
               border: 1px solid transparent;
               margin-bottom: 1px;
               font-weight: 550;
               padding: 0px ${paddingHorizontal || `calc(${height} / 2)`};
               cursor: pointer;
               text-transform: capitalize;

               ${!disabled && css`&:hover { opacity: 0.9; }`}

               &:active {
                  transform: translate3d(0, 1px, 0);
               }

               ${active && activeButtonStyle}
               ${disabled && disabledButtonStyle}
            `}
            {...buttonProps}>
            {isLoading && (
               <Spinner width="16px" height="16px" color={textColor} />
            )}
            {!isLoading && children}
            {!isLoading && icon && (
               <View
                  display="flex"
                  alignItems="center"
                  flex="0 0 auto"
                  paddingRight={name ? '16px' : '0px'}>
                  {icon}
               </View>
            )}
            {!isLoading && name && (
               <div
                  style={{
                     color: 'inherit',
                     width: '100%',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                  }}>
                  {name}
               </div>
            )}
         </ButtonComponent>
      </View>
   )
}

Button.propTypes = {
   active: PropTypes.bool,
   activeBackgroundColor: PropTypes.string,
   activeTextColor: PropTypes.string,
   borderRadius: PropTypes.string,
   children: PropTypes.node,
   color: PropTypes.string,
   disabled: PropTypes.bool,
   height: PropTypes.string,
   icon: PropTypes.node,
   linkExact: PropTypes.bool,
   linkIsExternal: PropTypes.bool,
   linkTo: PropTypes.string,
   mode: PropTypes.oneOf(['normal', 'loading']),
   name: PropTypes.string,
   onClick: PropTypes.func,
   paddingHorizontal: PropTypes.string,
   size: PropTypes.oneOf(['md', 'lg']),
   textColor: PropTypes.string,
   width: PropTypes.string
}

Button.defaultProps = {
   active: undefined,
   activeBackgroundColor: 'var(--listMenuButtonActiveBackgroundColor)',
   activeTextColor: 'var(--listMenuButtonActiveTextColor)',
   borderRadius: 'var(--baseComponentBorderRadius-md)',
   children: undefined,
   color: '#aaa',
   disabled: false,
   height: undefined,
   icon: undefined,
   linkExact: false,
   linkIsExternal: false,
   linkTo: undefined,
   mode: 'normal',
   name: undefined,
   onClick: undefined,
   paddingHorizontal: undefined,
   size: 'md',
   textColor: '#fff',
   width: 'auto'
}

export default Button
