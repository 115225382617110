// =========================================================================================@@
// Last Updated Date: Jan 16, 2022
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { Button, Spacer, View } from 'oio-react'
import PropTypes from 'prop-types'
import InitiativeEventIcon from 'assets/icons/initiativeEvent'
import InitiativeGroupIcon from 'assets/icons/initiativeGroup'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { Title } from 'src/sites/kits/UI'

const JoinWelcome = ({ onGoBack, onContinue, onUseBatchRegistration }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const InitiativeIcon = initiative.class === 'event'
      ? InitiativeEventIcon
      : InitiativeGroupIcon

   // eslint-disable-next-line camelcase
   const registrationFormEnabled = initiative.apps?.registration_form?.enabled
   const admissionsCommerceEnabled = initiative.commerce.enabled

   const hasJoinRequirements = registrationFormEnabled || admissionsCommerceEnabled

   return (
      <View
         display="flex"
         justifyContent="center"
         alignItems="center"
         textAlign="center"
         width="100%"
         height="100%">
         <View width="100%[a-b] 80%[c-f]" padding="24px">
            <View
               display="inline-flex"
               justifyContent="center"
               alignItems="center"
               width="60px"
               height="60px"
               borderRadius="50%"
               backgroundColor="#e4f0ea">
               <InitiativeIcon
                  width="20px"
                  height="20px"
                  color="#23372e"
               />
            </View>
            <Spacer size="3" />
            <Title size="lg">
               {initiative.name}
            </Title>
            <Spacer size="3" />
            <div className="ui-paragraph-text">
               {!hasJoinRequirements && (
                  <span>
                     {`Click the button below to ${initiative.joinVerb} this ${initiative.type.nameSingular}`}
                  </span>
               )}
               {hasJoinRequirements && (
                  <span>
                     You must
                     {registrationFormEnabled && ' fill in a form'}
                     {registrationFormEnabled && admissionsCommerceEnabled && ' and'}
                     {admissionsCommerceEnabled && ` select a ${initiative.commerce.customProductName || 'ticket'}`}
                     {` to ${initiative.joinVerb} this ${initiative.type.nameSingular}`}
                  </span>
               )}
            </div>
            <Spacer size="5" />
            <Button
               onClick={onContinue}
               size="lg"
               color="#35b865"
               name={hasJoinRequirements ? 'Continue' : initiative.joinVerb}
               textTransform="capitalize"
            />
            <Spacer size="8" />
            {/* initiative.batchRegistrationAllowed && (
               <>
                  <Text
                     size="1.5"
                     color="#666">
                     Registering multiple attendees or on behalf of someone else?
                  </Text>
                  <View
                     marginTop="5px"
                     onClick={onUseBatchRegistration}>
                     <Text
                        size="1.5"
                        color="#888"
                        style={{
                           cursor: 'pointer',
                           textDecoration: 'underline'
                        }}>
                        Click here to continue
                     </Text>
                  </View>
               </>
            ) */}
         </View>
      </View>
   )
}

JoinWelcome.propTypes = {
   onGoBack: PropTypes.func.isRequired,
   onContinue: PropTypes.func.isRequired,
   onUseBatchRegistration: PropTypes.func.isRequired
}

export default JoinWelcome
