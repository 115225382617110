// This component will be used for User Badges and Tags and auto-complete tokens
import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

const tokenSizes = {
   sm: {
      height: '16px',
      padding: '0px 8px',
      borderRadius: '12px',
      fontSize: '11px'
   },
   md: {
      height: '20px',
      padding: '0px 12px',
      borderRadius: '',
      fontSize: '12px'
   },
   lg: {
      height: '28px',
      padding: '0px 16px',
      borderRadius: '14px',
      fontSize: 'inherit'
   }
}

const Token = ({
   backgroundColor,
   marginBottom,
   marginRight,
   name,
   rightDetail,
   size,
   textColor
}) => (
   <View
      flex="0 0 auto"
      display="inline-flex"
      alignItems="center"
      height={tokenSizes[size].height}
      backgroundColor={backgroundColor}
      borderRadius={tokenSizes[size].borderRadius}
      marginBottom={marginBottom}
      marginRight={marginRight}
      padding={tokenSizes[size].padding}
      style={{
         fontSize: tokenSizes[size].fontSize,
         color: textColor
      }}>
      {name}
      {rightDetail}
   </View>
)

Token.propTypes = {
   backgroundColor: PropTypes.string,
   marginBottom: PropTypes.string,
   marginRight: PropTypes.string,
   name: PropTypes.string.isRequired,
   rightDetail: PropTypes.node,
   size: PropTypes.oneOf(['sm', 'md', 'lg']),
   textColor: PropTypes.string
}

Token.defaultProps = {
   backgroundColor: '#eee',
   marginBottom: undefined,
   marginRight: '3px',
   rightDetail: undefined,
   size: 'sm',
   textColor: '#555'
}

export default Token
