// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CallsToActionControl from 'src/sites/kits/Object/components/CallsToActionControl'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import { Avatar } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const ObjectActionBar = ({ showOriginalAuthor }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const showNumMembers = ['event', 'group'].includes(initiative.class) &&
      primaryRole?.numActiveUsers > 1

   return (
      <View
         display="flex"
         width="100%"
         alignItems="center"
         justifyContent={(showOriginalAuthor || showNumMembers)
            ? 'flex-start'
            : 'flex-end'
         }
         borderTop="1px solid var(--primaryLineColor)"
         paddingTop="32px"
         marginBottom="32px">
         {/* TODO: Will use in the future PR #967
            {showNumMembers && (
            <View display="none[a] flex[b-f]" alignItems="center">
               <View marginRight="15px">
                  <CheckmarkIcon width="12px" height="12px" color="#888" />
               </View>
               <Text
                  color="#888"
                  size="2"
                  lineHeight="160%">
                  {initiative.class === 'event'
                     ? `${primaryRole.numActiveUsers} people are attending this event`
                     : `${primaryRole.numActiveUsers} people are part of this group`
                  }
               </Text>
            </View>
         )} */}
         {showOriginalAuthor && (
            <View flex="0 0 auto" marginRight="16px">
               <Avatar
                  fullName={initiative.addedBy.fullName}
                  size="40px"
                  src={initiative.addedBy.avatarMedia?.file.thumbnailUrlW48}
               />
            </View>
         )}
         {showOriginalAuthor && (
            <View flex="1 1 auto">
               <Link to={`/-/profile/${initiative.addedBy.id}`}>
                  <span
                     className="weight-semibold"
                     style={{ color: 'var(--titleTextColor)', display: 'inline-block' }}>
                     {initiative.addedBy.fullName}
                  </span>
               </Link>
               <UserBadgeSash
                  badges={initiative.addedBy.badges}
                  style={{ margin: '0px 5px' }}
               />
               <div className="ui-meta-text">
                  {initiative.dateAdded}
               </div>
            </View>
         )}
         <CallsToActionControl />
      </View>
   )
}

ObjectActionBar.propTypes = {
   showOriginalAuthor: PropTypes.bool
}

ObjectActionBar.defaultProps = {
   showOriginalAuthor: false
}

export default ObjectActionBar
