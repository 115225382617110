// =========================================================================================@@
// Last Updated Date: Mar 30, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Grid, GridCell, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useOrganization } from 'src/core/graphql/hooks'
import ContentView from 'src/sites/kits/UI/Content/View'
import { useGlobalState } from 'src/sites/state'
import SocialMedia from '../SocialMedia'

const Footer = ({
   paddingLeft,
   paddingRight
}) => {
   const { theme } = useGlobalState()
   const { organization } = useOrganization()
   const currentYear = new Date().getFullYear()

   if (organization.customFooter) {
      return <ContentView elements={organization.customFooter.elements} />
   }

   return (
      <View
         display="flex"
         justifyContent="center"
         float="left"
         width="100%"
         height="180px"
         paddingVertical="30px"
         backgroundColor="var(--gatherFooterBackgroundColor)">
         <View
            width="100%"
            opacity="0.7"
            paddingLeft={paddingLeft || theme.tmpViewportPadding}
            paddingRight={paddingRight || theme.tmpViewportPadding}>
            <Grid columns="12">
               <GridCell colspan="12[a-c] 6[d-f]">
                  <div className="ui-meta-text">
                     &copy;
                     {` ${currentYear} ${organization.name}`}
                     {!organization.name.endsWith('.') && '.'}
                     {' All Rights reserved.'}
                  </div>
               </GridCell>
               <GridCell colspan="12[a-c] 6[d-f]">
                  <View
                     float="left"
                     width="100%"
                     display="flex"
                     justifyContent="flex-start[a-c] flex-end[d-f]">
                     <SocialMedia
                        color="var(--metaTextColor)"
                        format="icon-only"
                     />
                  </View>
               </GridCell>
            </Grid>
         </View>
      </View>
   )
}

// =======================================================
// Props, Decorators & Export
// =======================================================

Footer.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

Footer.defaultProps = {
   paddingLeft: undefined,
   paddingRight: undefined
}

export default Footer
