import React from 'react'
import { Grid, Modal, View } from 'oio-react'
import Helmet from 'react-helmet'
import { useParams, useRouteMatch } from 'react-router-dom'
import { LAYOUT_BOX } from 'config/constants/ui'
import { useOrganization, useUser } from 'src/core/graphql/hooks'
import AccountSettings from 'src/sites/kits/Account/apps/Settings'
import BanUserConfirm from 'src/sites/kits/Account/components/BanUserConfirm'
import SendMessage from 'src/sites/kits/Messages/apps/SendMessage'
import NotFound from 'src/sites/kits/Utils/NotFound'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useGlobalState } from 'src/sites/state'
import Collection from '~/apps/Collection'
import LoginHistory from '~/apps/LoginHistory'
import AboutBlock from '~/components/AboutBlock'
import FollowingBlock from '~/components/FollowingBlock'
import InitiativeClassBlock from '~/components/InitiativeClassBlock'
import SpaceBlock from '~/components/SpaceBlock'
import TempEmptyBlock from '~/components/TempEmptyBlock'

const Profile = () => {
   const { userId } = useParams()
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const isBoxGL = theme.tmpGlobalLayout === LAYOUT_BOX
   const isFixedWidthGL = theme.layoutContentFillMode === 'fixed'
   const initiativeClasses = ['group', 'discussion', 'event', 'post', 'resource', 'project']

   const containerPaddingLeft = isFixedWidthGL
      ? theme.tmpViewportPadding
      : `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
      `

   const containerPaddingRight = isFixedWidthGL
      ? theme.tmpViewportPadding
      : `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
         30px[d]
         30px[e]
         ${theme.tmpViewportPaddingF}[f]
      `

   const { organization } = useOrganization()
   const { user, error, networkStatus } = useUser({ id: userId })
   const initialLoading = networkStatus <= 2

   if (initialLoading) {
      return null
   }

   if (error) {
      return <NotFound />
   }

   return (
      <>
         <Helmet title={user.fullName} />
         <View
            marginTop={theme.viewportMarginTop}
            float="left"
            display="flex[d-f]"
            justifyContent="center"
            width="100%"
            paddingHorizontal={isFixedWidthGL && '100px[f]'}>
            <View
               display="block[a-d] flex[d-f]"
               width="100%"
               paddingLeft={containerPaddingLeft}
               paddingRight={containerPaddingRight}>
               <View
                  flex="0 0 auto"
                  width={isBoxGL
                     ? '100%[a-c] 350px[d] 380px[e] 380px[f]'
                     : '100%[a-c] 350px[d] 420px[e] 420px[f]'
                  }
                  paddingTop="30px[a-d] 40px[e-f]"
                  paddingLeft={isFixedWidthGL
                     ? '0px'
                     : '0px[a-c] 24px[d-f]'
                  }
                  paddingRight={isFixedWidthGL
                     ? '30px[d] 50px[e-f]'
                     : '0px[a-c] 24px[d-f]'
                  }
                  borderRight="1px solid var(--columnLineColor)[d-f]">
                  <AboutBlock />
               </View>
               <View flex="1 1 auto" height="100%">
                  <View
                     float="left"
                     width="100%"
                     height="100%"
                     minHeight="100vh"
                     paddingTop="30px[a-d] 40px[e-f]"
                     paddingLeft="30px[d] 40px[e-f]">
                     <View marginBottom="20px">
                        <TempEmptyBlock />
                        <Grid columns="1" spacing="30px">
                           {initiativeClasses.map(initiativeClass => (
                              <InitiativeClassBlock
                                 key={initiativeClass}
                                 initiativeClass={initiativeClass}
                              />
                           ))}
                           {userId === 'me' && <FollowingBlock />}
                        </Grid>
                     </View>
                     {userId === 'me' && <SpaceBlock />}
                  </View>
               </View>
            </View>
         </View>
         <ModalRoute path={`${match.path}/collection/:collectionFilter`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 360px[b] 90%[c] 650px[d-f]"
                  height="100%[a] 80%[b] 90%[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <Collection onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         {organization.currentUserCanEdit && (
            <ModalRoute path={`${match.path}/ban-user`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     width="100%[a-b] 400px[c-f]"
                     maxHeight="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <BanUserConfirm
                        onBanComplete={() => window.location.assign('/')}
                        onCancelButtonClick={onCloseTrigger}
                        userId={userId}
                     />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {userId !== 'me' && (
            <ModalRoute path={`${match.path}/send-message`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     width="90%[a-b] 500px[c-f]"
                     maxHeight="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <SendMessage
                        onCloseButtonClick={onCloseTrigger}
                        onSend={onCloseTrigger}
                        recipientFirstName={user.firstName}
                     />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {userId === 'me' && (
            <>
               <ModalRoute path={`${match.path}/settings`}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <Modal
                        borderRadius="5px"
                        width="100%[a] 360px[b] 90%[c] 720px[d-f]"
                        height="100%[a] 80%[b] 570px[c-f]"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <AccountSettings onCloseButtonClick={onCloseTrigger} />
                     </Modal>
                  )}
               </ModalRoute>
               <ModalRoute path={`${match.path}/login-history`}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <Modal
                        borderRadius="5px"
                        width="100%"
                        height="100%[a-b] 95%[c-f]"
                        maxWidth="650px"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <LoginHistory onCloseButtonClick={onCloseTrigger} />
                     </Modal>
                  )}
               </ModalRoute>
            </>
         )}
      </>
   )
}

export default Profile
