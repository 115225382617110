// =========================================================================================@@
// Last Updated Date: Jan 22, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import ProfileMoreButton from 'src/sites/kits/Profile/components/MoreButton'
import { useOrganization } from 'src/core/graphql/hooks'

const ProfileActions = () => {
   const match = useRouteMatch()
   const { userId } = useParams()

   const { organization } = useOrganization()
   const { highlightColor } = organization.theme

   const buttonProps = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'var(--baseComponentHeight-lg)',
      width: '100%',
      backgroundColor: highlightColor,
      borderRadius: '3px',
      style: { pointer: 'cursor' }
   }

   return (
      <>
         {userId === 'me' && (
            <>
               <Link to={`${match.url}/settings`} style={{ flex: '1 1 auto' }}>
                  <View {...buttonProps}>
                     <Text size="1.5" color="#fff">
                        Edit Profile
                     </Text>
                  </View>
               </Link>
               <Spacer size="1" orientation="vertical" />
               <Link to="/account/logout" style={{ flex: '1 1 auto' }}>
                  <View {...buttonProps}>
                     <Text size="1.5" color="#fff">
                        Logout
                     </Text>
                  </View>
               </Link>
            </>
         )}
         {userId !== 'me' && (
            <Link to={`${match.url}/send-message`} style={{ flex: '1 1 auto' }}>
               <View {...buttonProps}>
                  <Text size="1.5" color="#fff" transform="capitalize">
                     Send Message
                  </Text>
               </View>
            </Link>
         )}
         <Spacer size="1" orientation="vertical" />
         <ProfileMoreButton color={highlightColor} iconColor="#fff" />
      </>
   )
}

export default ProfileActions
