// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'
import FileListItemRowSmall from 'src/sites/kits/Files/components/FileListItemRowSmall'
import FilesDragAndDropKit from 'src/sites/kits/Files/components/FilesDragAndDropKit'
import CustomMetadataBlock from 'src/sites/kits/InitiativeSettings/components/CustomMetadataBlock'
import { Input, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import { useFileUploads, useStatefulRef } from 'src/sites/kits/Utils'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import style from 'src/sites/style'

const DiscussionSettings = ({ returnUrl }) => {
   const editorRef = useRef(null)
   const [editorScrollContainer, setEditorScrollContainer] = useStatefulRef(null)

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const [files, setFiles] = useState(initiative.body.elements?.[1]?.fileInstances
      .map(instance => instance.file) ?? [])

   const { uploadFile, uploadsInProgress, resetFileUploadState } = useFileUploads({
      targetType: 'initiative', targetId: initiative.id, purpose: 'contentEmbed' })

   const { highlightColor: buttonColor } = organization.theme
   const { showNotification } = useContext(NotificationManagerContext)
   const stylesheet = style.stylesheet({ ...organization.typeStyles })

   const initiativeType = organization.initiativeTypes.find(t => t.id === initiative.type.id)
   const defaultInitiativeBody = initiativeType.defaultBody?.replace('\n', '<br />') ?? ''
   const initiativeBody = initiative.body?.elements?.[0]?.body || defaultInitiativeBody

   const [isSaving, setIsSaving] = useState(false)
   const [isPublishing, setIsPublishing] = useState(false)
   const isDraft = initiative.privacy === 'draft'
   let publishButtonMode = isPublishing ? 'loading' : 'normal'
   if (isSaving) {
      publishButtonMode = 'disabled'
   }

   let saveButtonMode = isSaving ? 'loading' : 'normal'
   if (isPublishing) {
      saveButtonMode = 'disabled'
   }

   // TODO: Generalize
   // See: PR #448, Issue #452
   const editorEventConfigProps = {
      events: {
         'image.beforeUpload': async (images) => {
            const currentEditor = editorRef.current.editor
            const { uploadedMediaUrl: imgUrl } = await uploadFile({ file: images[0] })
            currentEditor.image.insert(imgUrl, null, null, currentEditor.image.get())
         }
      }
   }

   const handleFileUpload = async (filesToUpload) => {
      try {
         const result = await uploadFile({ file: filesToUpload[0] })
         const newFile = {
            id: result.fileId,
            name: filesToUpload[0].name
         }

         setFiles(prevFiles => [...prevFiles, newFile])
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleRemoveFile = (fileId) => {
      setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId))
   }

   const handleSaveInitiative = async (values) => {
      if (uploadsInProgress.length) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         return
      }

      const updatePayload = {
         ...values,
         body: [{
            operation: 'updateRichText',
            updateRichText: {
               id: initiative.body.elements[0].id,
               body: editorRef.current.editor.html.get(true)
            }
         }],
         tags: values.tags.trim().length > 0
            ? values.tags.split(',')
            : []
      }

      const fileContentOperation = initiative.body.elements.length === 1
         ? 'createFileList'
         : 'updateFileList'

      // If files were uploaded
      if (files.length) {
         const fileIds = files.map(f => f.id)
         updatePayload.body.push({
            operation: fileContentOperation,
            [fileContentOperation]: {
               fileIds,
               id: fileContentOperation === 'updateFileList'
                  ? initiative.body.elements[1].id
                  : undefined
            }
         })
      }

      // If all files were removed (and fileList element was already created)
      if (initiative.body.elements.length > 1 && !files.length) {
         updatePayload.body.push({
            operation: 'remove',
            remove: initiative.body.elements[1].id
         })
      }

      try {
         await updateInitiative({ id: initiative.id }, updatePayload)
         resetFileUploadState()
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         setIsSaving(false)
         setIsPublishing(false)
      }
   }

   return (
      <>
         <Formik
            initialValues={{
               name: initiative.name,
               tags: initiative.tags.join(', ')
            }}
            onSubmit={handleSaveInitiative}
            render={({ handleSubmit, setFieldValue }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#f8f8f8"
                  elementFocusBackgroundColor="#f3f3f3"
                  labelTextColor="#000"
                  labelTextSize="2.5"
                  labelTextTransform="none"
                  labelTextWeight="medium"
                  onSubmit={handleSubmit}>
                  <View
                     position="absolute"
                     top="0px"
                     left="0px"
                     right="0px"
                     bottom="0px"
                     borderRadius="5px"
                     style={{ overflow: 'hidden' }}>
                     <Helmet
                        title={`${initiative.type.nameSingular} Edit | ${initiative.name}`}
                     />
                     <View
                        display="flex"
                        justifyContent="flex-end[a] space-between[b-f]"
                        alignItems="center"
                        position="absolute"
                        top="0px"
                        right="0px"
                        left="0px"
                        height="70px"
                        backgroundColor="#fafafa"
                        borderBottom="1px solid #e5e5e5"
                        padding="0px 0px 0px 30px"
                        zIndex="2">
                        <View display="none[a] flex[b-f]">
                           <Text size="3" weight="medium" color="#333">
                              {`Edit ${initiative.type.nameSingular}`}
                           </Text>
                        </View>
                        <View display="flex" alignItems="center">
                           <Button
                              onClick={() => {
                                 setIsSaving(true)
                                 // This is in the event the user attempted to post. failed,
                                 // then saved draft
                                 if (isDraft) {
                                    setFieldValue('privacy', 'draft', false)
                                 }
                                 handleSubmit()
                              }}
                              color={isSaving ? '#111' : '#e5e5e5'}
                              mode={saveButtonMode}
                              name={isDraft ? 'Save Draft' : 'Save Changes'}
                              padding="25px"
                              size="sm"
                              textColor="#000"
                              type="submit"
                           />
                           {isDraft && (
                              <>
                                 <Spacer size="1" orientation="vertical" />
                                 <Button
                                    onClick={() => {
                                       setIsPublishing(true)
                                       setFieldValue('privacy', 'inherit', false)
                                       handleSubmit()
                                    }}
                                    color={buttonColor}
                                    mode={publishButtonMode}
                                    padding="25px"
                                    size="sm"
                                    textColor="#fff"
                                    type="submit"
                                    name="Post"
                                 />
                              </>
                           )}
                           <Link to={returnUrl}>
                              <View
                                 display="flex"
                                 justifyContent="center"
                                 alignItems="center"
                                 height="70px"
                                 width="70px"
                                 marginLeft="20px"
                                 borderLeft="1px solid #e5e5e5">
                                 <CloseIcon width="24px" height="24px" color="#666" />
                              </View>
                           </Link>
                        </View>
                     </View>
                     <View
                        position="absolute"
                        top="0px"
                        left="0px"
                        bottom="0px"
                        right="0px"
                        backgroundColor="#fafafa">
                        <View
                           ref={setEditorScrollContainer}
                           position="absolute"
                           top="70px"
                           left="0px"
                           bottom="0px"
                           right="0px"
                           scroll="on">
                           <View width="100%">
                              <View
                                 float="left"
                                 width="100%[a-b] calc(100% - 300px)[c-f]"
                                 minHeight="100vh[c-f]"
                                 borderRight="1px solid #e5e5e5[c-f]"
                                 backgroundColor="#fff">
                                 {isDraft && (
                                    <View
                                       width="100%"
                                       padding="20px 30px"
                                       backgroundColor="rgba(255, 235, 131, 0.2)">
                                       <Text size="1.5[a-b] 2[c-f]" color="rgba(163, 117, 75, 1)">
                                          You are currently in Draft mode
                                       </Text>
                                    </View>
                                 )}
                                 <View
                                    width="100%"
                                    borderRadius="5px"
                                    padding="30px">
                                    <Input
                                       size="xl"
                                       maxLength="120"
                                       name="name"
                                       placeholder={`Enter a title for your ${initiative.type.nameSingular}...`}
                                       style={{
                                          padding: 0,
                                          fontSize: 32,
                                          fontWeight: '500'
                                       }}
                                    />
                                    <Spacer size="5" />
                                    <View display="block" width="100%">
                                       <HtmlEditor
                                          ref={editorRef}
                                          scrollContainer={editorScrollContainer}
                                          scrollContainerTopOffset="70px"
                                          config={{
                                             heightMin: 400,
                                             paragraphFormat: {
                                                N: 'Body',
                                                pre: 'Code'
                                             },
                                             placeholderText: `Write more about this ${initiative.type.nameSingular}...`,
                                             ...editorEventConfigProps
                                          }}
                                          initialValue={initiativeBody}
                                          stylesheet={stylesheet}
                                          toolbarConfig="advanced"
                                       />
                                    </View>
                                    <Spacer size="3" />
                                    <Text size="2" weight="medium" color="#333">
                                       Tags (seperated by commas)
                                    </Text>
                                    <Spacer size="1" />
                                    <Textarea
                                       size="sm"
                                       name="tags"
                                       placeholder="Enter tags..."
                                    />
                                 </View>
                                 <Spacer size="5" />
                              </View>
                              <View float="left" width="100%[a-b] 300px[c-f]">
                                 <View
                                    padding="10px 15px"
                                    backgroundColor="#eee">
                                    <Text size="0.8" weight="medium" transform="uppercase">
                                       File Attachments
                                    </Text>
                                 </View>
                                 <FilesDragAndDropKit.DragAndDropContainer
                                    onFileUpload={handleFileUpload}>
                                    {dragSource => (
                                       <View
                                          backgroundColor={dragSource === 'externalFileDragOver' && 'rgba(255, 251, 198, 0.3)'}>
                                          {files.map((file, index) => (
                                             <FileListItemRowSmall
                                                key={file.id}
                                                id={file.id}
                                                infected={file.infected}
                                                mimetypeMismatch={file.mimetypeMismatch}
                                                mimetypeVerified={file.mimetypeVerified}
                                                name={file.name}
                                                onRemoveButtonClick={handleRemoveFile}
                                                showRemoveButton
                                                status={file.status}
                                             />
                                          ))}
                                          <View width="100%" textAlign="center" padding="20px">
                                             <Text size="1" color="#666">
                                                {`Select files to attach to this ${initiative.type.nameSingular}`}
                                             </Text>
                                             <Spacer size="1" />
                                             <FileInput onChange={handleFileUpload}>
                                                <Button
                                                   size="xs"
                                                   name="Upload File"
                                                   color="#ddd"
                                                   textColor="#333"
                                                />
                                             </FileInput>
                                          </View>
                                       </View>
                                    )}
                                 </FilesDragAndDropKit.DragAndDropContainer>
                                 <CustomMetadataBlock />
                              </View>
                           </View>
                        </View>
                     </View>
                  </View>
               </Form>
            )}
         />
      </>
   )
}

DiscussionSettings.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default DiscussionSettings
