// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import { useParams } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import ProfileActions from 'src/sites/kits/Profile/components/Actions'
import { Avatar, Title, Token } from 'src/sites/kits/UI'
import { useUser } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const ProfileAboutBlock = () => {
   const { userId } = useParams()
   const { theme } = useGlobalState()
   const { user } = useUser({ id: userId })

   const tokenBackgroundColor = tinycolor('#111').setAlpha(0.1).toRgbString()

   return (
      <View width="100%" flexFlow="column" height="100%[d-f]">
         <View flex="1 1 auto">
            <View
               display="flex"
               flexFlow="row-reverse"
               width="100%">
               <Avatar
                  size="70px[a-b] 90px[c] 60px[d-f]"
                  src={user.avatarMedia?.file.thumbnailUrlW240}
               />
               <View
                  flex="1 1 auto"
                  width="100%"
                  paddingRight="20px[a-d]">
                  <Title size="lg">
                     {user.fullName}
                  </Title>
                  <View
                     className="ui-paragraph-text"
                     marginTop="5px">
                     {user.position}
                     {user.position && user.organization && ', '}
                     {user.organization}
                  </View>
                  <UserBadgeSash
                     badges={user.badges}
                     size="lg"
                     style={{ margin: '15px 0 0 0' }}
                  />
               </View>
            </View>
            {(user.bio || user.skills.length > 0 || user.certifications.length > 0) && (
               <View
                  marginTop={theme.tmpSpacingTop}
                  width="100%"
                  borderTop="1px solid var(--primaryLineColor)"
               />
            )}
            {user.bio && (
               <View
                  className="ui-paragraph-text"
                  maxWidth="650px"
                  marginTop={theme.tmpSpacingTop}>
                  {user.bio}
               </View>
            )}
            {user.skills.length > 0 && (
               <View
                  width="100%"
                  paddingTop="20px[a-d] 30px[e-f]">
                  <Title size="xs">
                     Skills
                  </Title>
                  <View maxWidth="650px" marginTop="10px" opacity="0.7">
                     {user.skills.map(skill => (
                        <Token
                           key={skill}
                           backgroundColor={tokenBackgroundColor}
                           marginBottom="3px"
                           name={skill}
                           size="lg"
                           textColor="var(--bodyTextColor)"
                        />
                     ))}
                  </View>
               </View>
            )}
            {user.certifications.length > 0 && (
               <View
                  width="100%"
                  marginTop="20px[a-d] 30px[e-f]">
                  <Title size="xs">
                     Certifications
                  </Title>
                  <View maxWidth="650px" marginTop="10px" opacity="0.7">
                     {user.certifications.map(certification => (
                        <Token
                           key={certification}
                           backgroundColor={tokenBackgroundColor}
                           marginBottom="3px"
                           name={certification}
                           size="lg"
                           textColor="var(--bodyTextColor)"
                        />
                     ))}
                  </View>
               </View>
            )}
         </View>
         <View
            flex="0 0 auto"
            maxWidth="350px[a-d]"
            display="flex"
            marginTop="20px">
            <ProfileActions />
         </View>
      </View>
   )
}

export default ProfileAboutBlock
