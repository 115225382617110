// =========================================================================================@@
// Last Updated Date: Jan 22, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { ListMenu, ListMenuButton, Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams, useRouteMatch } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import UserBadgeManage from 'src/sites/kits/People/apps/UserBadgeManage'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import Popover from 'src/sites/kits/Utils/Popover'
import { useOrganization } from 'src/core/graphql/hooks'

const ProfileMoreButton = ({ color, iconColor }) => {
   const { userId } = useParams()
   const match = useRouteMatch()

   const { organization } = useOrganization()
   const showAdminActions = organization.currentUserCanEdit
   const showMyActions = userId === 'me'

   if (!organization.currentUserCanEdit && userId !== 'me') {
      return null
   }

   const adminActionButtons = [{
      linkTo: `${match.url}/manage-badges/${userId}`,
      name: 'Assign/Revoke Badges'
   }]

   if (userId !== 'me') {
      adminActionButtons.push({
         linkTo: `${match.url}/ban-user`,
         name: 'Ban this user'
      })
   }

   const myActionButtons = [{
      linkTo: `${match.url}/login-history`,
      name: 'View Login History'
   }]

   return (
      <>
         <Popover.Provider>
            <Popover.Anchor>
               <View
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="var(--baseComponentHeight-lg)"
                  width="var(--baseComponentHeight-lg)"
                  backgroundColor={color}
                  borderRadius="3px"
                  style={{ pointer: 'cursor' }}>
                  <MoreIcon width="18px" height="18px" color={iconColor} />
               </View>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="bottom"
               borderRadius="3px"
               width="200px">
               <View
                  float="left"
                  width="100%"
                  borderRadius="3px"
                  margin="12px 0px"
                  backgroundColor="#fff"
                  border="1px solid #eee"
                  boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                  <View
                     float="left"
                     width="100%">
                     <ListMenu buttonSize="sm">
                        {showAdminActions && adminActionButtons.map(button => (
                           <ListMenuButton
                              key={button.name}
                              linkTo={button.linkTo}
                              name={button.name}
                           />
                        ))}
                        {showMyActions && myActionButtons.map(button => (
                           <ListMenuButton
                              key={button.name}
                              linkTo={button.linkTo}
                              name={button.name}
                           />
                        ))}
                     </ListMenu>
                  </View>
               </View>
            </Popover.View>
         </Popover.Provider>
         <ModalRoute path={`${match.path}/manage-badges/:userId`}>
            {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="90%[a-b] 500px[c-f]"
                  maxHeight="100%"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <UserBadgeManage
                     onCloseButtonClick={onCloseTrigger}
                     userId={modalRouteMatch.params.userId}
                  />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

ProfileMoreButton.propTypes = {
   color: PropTypes.string,
   iconColor: PropTypes.string
}

ProfileMoreButton.defaultProps = {
   color: '#eee',
   iconColor: '#444'
}

export default ProfileMoreButton
