import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input, Select } from 'src/sites/kits/Utils/ConnectedForm'
import { useUpdateOrganization } from 'src/core/graphql/hooks'

const InitiativeTypeCreate = ({ onCancelButtonClick, onCreate }) => {
   const { updateOrganization } = useUpdateOrganization()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleUpdateOrganization = async (values, actions) => {
      try {
         await updateOrganization({
            initiativeTypes: [{
               operation: 'create',
               create: values
            }]
         })

         if (onCreate) {
            onCreate()
         }
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="3" weight="medium">
               Create New Content Type
            </Text>
         </View>
         <Formik
            initialValues={{
               nameSingular: '',
               class: 'event'
            }}
            onSubmit={handleUpdateOrganization}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  onSubmit={handleSubmit}>
                  <View padding="18px">
                     <View>
                        <Text size="2" weight="medium" color="#222">
                           Content Type Name
                        </Text>
                        <Text size="1.5" color="#777">
                           {'Enter a name for this new Content Type (enter the singular name of this content type. For example: committee, not committees)'}
                        </Text>
                        <Spacer size="2" />
                        <Input name="nameSingular" size="lg" placeholder="Content Type Name" />
                        <Spacer size="2" />
                        <Text size="2" weight="medium" color="#222">
                           Content Type Classification
                        </Text>
                        <Text size="1.5" color="#777">
                           What is the best definition of this new content type?
                        </Text>
                        <Spacer size="2" />
                        <Select name="class">
                           <option value="event">Event</option>
                           <option value="group">Group</option>
                           <option value="project">Project</option>
                           <option value="resource">Resource</option>
                           <option value="post">Post</option>
                        </Select>
                     </View>
                     <Spacer size="3" />
                     <View display="flex" justifyContent="flex-end">
                        <Button
                           width="50%"
                           onClick={onCancelButtonClick}
                           name="Cancel"
                           color="#fff"
                           textColor="#666"
                        />
                        <Button
                           width="50%"
                           type="submit"
                           name="Create"
                           color="#f7b77c"
                           textColor="#111"
                           mode={isSubmitting ? 'loading' : 'normal'}
                        />
                     </View>
                  </View>
               </Form>
            )}
         />
      </View>
   )
}

InitiativeTypeCreate.propTypes = {
   onCancelButtonClick: PropTypes.func,
   onCreate: PropTypes.func
}

InitiativeTypeCreate.defaultProps = {
   onCancelButtonClick: undefined,
   onCreate: undefined
}

export default InitiativeTypeCreate
