// =========================================================================================@@
// Last Updated Date: Mar 13, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import ObjectJoinButton from 'src/sites/kits/Object/components/JoinButton'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import BookmarkButton from './BookmarkButton'
import SubscribeButton from './SubscribeButton'

const CallsToActionControl = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const isDraft = initiative.privacy === 'draft'
   const isEvent = initiative.class === 'event'
   const isGroup = initiative.class === 'group'
   const isSession = initiative.class === 'session'

   const { currentUserCanEdit, currentUserCanModerateMessages, currentUserCanRemove } = initiative
   const userHasExtraPermissions = currentUserCanEdit ||
      currentUserCanModerateMessages ||
      currentUserCanRemove

   return (
      <View
         display="flex"
         alignItems="center"
         width={(isEvent || isGroup) && '100%'}
         borderTop={isEvent && '1px solid var(--primaryLineColor)'}
         marginTop={isEvent && '24px'}
         paddingTop={isEvent && '32px'}
         flex="0 0 auto">
         {!isDraft && initiative.class === 'discussion' && (
            <SubscribeButton />
         )}
         {initiative.class !== 'discussion' && (
            <>
               <ObjectJoinButton
                  buttonRounded
                  buttonSize="lg"
                  popoverAnchorOriginVertical="bottom"
               />
               {!isSession && <View flex="1 1 auto" />}
               <View flex="0 0 auto">
                  <BookmarkButton
                     color="var(--bodyBackgroundColor)"
                     format={isSession ? 'iconAndText' : 'iconOnly'}
                     textColor="#444"
                  />
               </View>
               {isSession && <View flex="0 0 auto" width="8px" height="8px" />}
            </>
         )}
         {userHasExtraPermissions && (
            <View flex="0 0 auto" marginLeft="4px">
               <ObjectMoreButton
                  buttonSize="var(--baseComponentHeight-lg)"
                  color="var(--bodyBackgroundColor)"
                  popoverAnchorOriginHorizontal="right"
                  popoverAnchorOriginVertical="top"
               />
            </View>
         )}
      </View>
   )
}

export default CallsToActionControl
